import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";
import { black70, gray15 } from "styles/colors";
import fontSizes from "styles/fontSizes";
import contactNumber from "utils/contactNumber";

const Container = styled.div`
  text-align: center;
  ${({ $dividers }) =>
    $dividers &&
    `
    border-top: 2px solid ${gray15};
  `}
  ${media.medium`
    ${({ $dividers }) =>
      $dividers &&
      `    
      display: inline-table;
      width: -webkit-fill-available;
    `}
  `}
`;

const Heading = styled.h2`
  margin-top: 16px;
  margin-bottom: 4px;
  color: ${black70};
  ${fontSizes[4]}
  line-height: 28px;
  font-weight: 600;
  ${media.medium`
    margin-top: 32px;
    margin-bottom: 8px;
    line-height: 40px;
    ${fontSizes[6]}
  `}
`;

const Details = styled.div`
  margin-bottom: ${spacing[5]};
  p {
    margin-block: 0;
    color: ${black70};
    ${fontSizes[2]}
    line-height: 22px;
  }
  strong {
    font-weight: 600;
  }
  ${media.medium`
    p {
      ${fontSizes[5]}
      line-height: 32px;
    }
  `}
  ${media.extraLarge`
    display: inline-flex;
    gap: 8px;
  `}
`;

const Divider = styled.div`
  ${({ $dividers }) =>
    $dividers &&
    `
    padding: 0 11px;
  `}
  ${media.medium`
    ${({ $dividers }) =>
      $dividers &&
      `    
      padding: 0 27px;
    `}
  `}
`;

const ContactCallout = ({ dividers }) => {
  return (
    <Container $dividers={dividers}>
      <Heading>Cruise with Confidence</Heading>
      <Details>
        <p>We’re here to help you navigate cruising safely.</p>
        <p>
          Call <strong>{contactNumber}</strong> or E-mail
          <strong> contact@gotosea.com</strong>
        </p>
      </Details>
      <Divider $dividers={dividers}>
        <hr color={gray15} size="1" />
      </Divider>
    </Container>
  );
};

ContactCallout.propTypes = {
  dividers: PropTypes.bool,
};

export default ContactCallout;
