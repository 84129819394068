import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "styles/media";
import { spacingMixin } from "styles/spacing";
import { black70, gray50 } from "styles/colors";
import fontSizes from "styles/fontSizes";
import Link from "next/link";
import Chevron from "public/icons/chevron.svg";
import {
  FEATURED_DEPARTURE_PORT_SHAPE,
  FEATURED_DESTINATION_SHAPE,
  CRUISELINE_SHAPE,
} from "constants/propTypes";
import ColumnedVerticalList from "components/ColumnedVerticalList";
import { isSmallScreen } from "utils/getScreenSize";
import ContactCallout from "./ContactCallout";

const Nav = styled.nav`
  display: grid;
  grid-gap: 0;
  grid-template-columns: auto;
  margin: 30px 32px 64px 16px;
  ${media.medium`
    margin: 64px 32px;
    grid-gap: 64px;
    grid-template-columns: auto auto auto auto;
  `}
`;

const Section = styled.section`
  position: relative;
  text-align: left;
  ${spacingMixin}
  p {
    margin: 0;
    color: ${gray50};
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 32px;
  grid-template-rows: 40px;
  ${media.medium`
    margin: 0;
    grid-gap: 64px;
    grid-template-columns: auto;
    grid-template-rows: none;
  `}
`;

const Heading = styled.h3`
  height: 26px;
  margin: 8px 0;
  color: ${black70};
  ${fontSizes[3]};
  line-height: 26px;
  font-weight: 600;
  ${media.medium`
    height: unset;
  `}
  ${spacingMixin}
  a {
    color: ${black70};
    text-decoration: none;
  }
`;

const Expander = styled.a`
  display: flex;
  width: 100%;
  margin: 8px 0;
  padding: 5px;
  cursor: pointer;
  ${media.medium`
    display: none;
  `}
`;

const StyledChevron = styled(Chevron)`
  transition: 0.5s ease;
  transform: ${({ $isExpanded }) =>
    $isExpanded ? "rotate(180deg)" : "rotate(0deg)"};
`;

const SectionHeader = ({ label, handleClick, isExpanded }) => {
  return (
    <Header>
      <Heading>{label}</Heading>
      <Expander onClick={handleClick}>
        <StyledChevron
          height="22px"
          width="22px"
          fill={black70}
          $isExpanded={isExpanded}
        />
      </Expander>
    </Header>
  );
};

SectionHeader.propTypes = {
  label: PropTypes.string,
  handleClick: PropTypes.func,
  isExpanded: PropTypes.bool,
};

const FatFooter = ({
  dividers,
  departurePorts,
  destinations,
  cruiselines,
  months,
}) => {
  const isSmall = isSmallScreen();

  const [isDestinationExpanded, setDestinationExpanded] = useState(null);
  const [isPortExpanded, setPortExpanded] = useState(null);
  const [isCruiseLineExpanded, setCruiseLineExpanded] = useState(null);
  const [isMonthExpanded, setMonthExpanded] = useState(null);

  useEffect(() => {
    if (isSmall) {
      setDestinationExpanded(false);
      setPortExpanded(false);
      setCruiseLineExpanded(false);
      setMonthExpanded(false);
    } else {
      setDestinationExpanded(true);
      setPortExpanded(true);
      setCruiseLineExpanded(true);
      setMonthExpanded(true);
    }
  }, [isSmall]);

  return (
    <>
      <ContactCallout dividers={dividers} />
      <Nav>
        <Section id="destinations" $spacing={0}>
          <SectionHeader
            label="Best Values by Destination"
            handleClick={() => setDestinationExpanded(!isDestinationExpanded)}
            isExpanded={isDestinationExpanded}
          />
          {isDestinationExpanded && (
            <ColumnedVerticalList
              columns={1}
              mdColumns={1}
              lgColumns={1}
              items={destinations.map((destination) => ({
                label: destination.name,
                href: destination.urlname,
              }))}
            />
          )}
        </Section>

        <Section id="departure-ports" $spacing={0}>
          <SectionHeader
            label="Best Values by Departure Port"
            handleClick={() => setPortExpanded(!isPortExpanded)}
            isExpanded={isPortExpanded}
          />
          {isPortExpanded && (
            <ColumnedVerticalList
              columns={1}
              mdColumns={1}
              lgColumns={1}
              items={departurePorts.map((port) => ({
                label: port.name,
                href: port.urlname,
              }))}
            />
          )}
        </Section>

        <Section id="cruise-line" $spacing={0}>
          <SectionHeader
            label="Best Values by Cruise Line"
            handleClick={() => setCruiseLineExpanded(!isCruiseLineExpanded)}
            isExpanded={isCruiseLineExpanded}
          />
          {isCruiseLineExpanded && (
            <ColumnedVerticalList
              columns={1}
              mdColumns={1}
              lgColumns={1}
              items={cruiselines.map((cruiseline) => ({
                label: cruiseline.name,
                href: `search?ship__cruiseline_id=${cruiseline.id}`,
              }))}
            />
          )}
        </Section>

        <Section $spacing={0}>
          <SectionHeader
            label="Best Values by Month"
            handleClick={() => setMonthExpanded(!isMonthExpanded)}
            isExpanded={isMonthExpanded}
          />
          {isMonthExpanded && (
            <ColumnedVerticalList
              columns={1}
              mdColumns={1}
              lgColumns={1}
              items={months.map((cruise) => ({
                label: cruise.name,
                href: cruise.urlname,
              }))}
            />
          )}
          <Heading $spacingTop={3} $spacingTopMedium={5}>
            <Link href="/about-us">About Us</Link>
          </Heading>
          <Heading $spacingTop={3} $spacingTopMedium={5}>
            <Link href="/affiliate">Affiliate Program</Link>
          </Heading>
          <Heading $spacingTop={3} $spacingTopMedium={5}>
            <a href="mailto:contact@gotosea.com">Contact Us</a>
          </Heading>
        </Section>
      </Nav>
    </>
  );
};

FatFooter.propTypes = {
  dividers: PropTypes.bool,
  departurePorts: PropTypes.arrayOf(FEATURED_DEPARTURE_PORT_SHAPE),
  destinations: PropTypes.arrayOf(FEATURED_DESTINATION_SHAPE),
  cruiselines: PropTypes.arrayOf(CRUISELINE_SHAPE),
  months: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      urlname: PropTypes.string,
    })
  ),
};

export default FatFooter;
