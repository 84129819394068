import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { black70, gray10, gray15 } from "styles/colors";
import media from "styles/media";
import fontSizes from "styles/fontSizes";

const TabItem = ({ href, children }) => {
  return href ? (
    <a href={href} tabIndex={0}>
      {children}
    </a>
  ) : (
    <div>{children}</div>
  );
};

TabItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  href: PropTypes.string,
};

const StyledList = styled.ul`
  -webkit-appearance: none;
  padding: 0;
  margin: -0.5rem;
  columns: ${({ columns }) => columns};
  ${({ $mdColumns }) =>
    $mdColumns &&
    media.medium`
      columns: ${$mdColumns};
  `}
  ${({ $lgColumns }) =>
    $lgColumns &&
    media.large`
      columns: ${$lgColumns};
  `}
  a, div {
    font-weight: 400;
    ${fontSizes[1]};
    transition: all 0.5s ease;
    color: ${black70};
    text-decoration: none;
    padding: 0.5rem;
  }
  li {
    list-style-type: none;
    width: 100%;
    display: inline-block;
  }
  a:hover {
    cursor: pointer;
    background-color: ${gray10};
  }
  a:focus {
    outline: none;
    background-color: ${gray15};
  }
  a {
    display: block;
  }
`;

const ColumnedVerticalList = ({
  items = [],
  columns = 1,
  mdColumns = 3,
  lgColumns = 5,
  ...props
}) => {
  return (
    <StyledList
      $columns={columns}
      $mdColumns={mdColumns}
      $lgColumns={lgColumns}
      {...props}
    >
      {items.map(({ label, href }) => (
        <li key={label}>
          <TabItem href={href}>{label}</TabItem>
        </li>
      ))}
    </StyledList>
  );
};

ColumnedVerticalList.propTypes = {
  columns: PropTypes.number,
  mdColumns: PropTypes.number,
  lgColumns: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string,
    })
  ),
};

export default ColumnedVerticalList;
